import { Stack } from '@mui/material';

const SPACING = '15px';
const DailyActivityNavContainer = ({ children }: Props) => (
  <Stack spacing={SPACING} width={'100%'} alignItems={'center'} sx={{ marginBottom: SPACING }}>
    {children}
  </Stack>
);

type Props = {
  children: React.ReactNode;
};

export default DailyActivityNavContainer;
