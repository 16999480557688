import { LevelDisplayName, Role } from '../@types/auth';
import React from 'react';
import useAuth from '../hooks/useAuth';
import useUserFlagContext from '../hooks/useUserFlagContext';
import { IS_SURVEY_VIEWED } from '../contexts/UserFlagContext';
import Banner from './banner/Banner';

const SurveyBanner = () => {
  const { user } = useAuth();
  const role = user?.alphaAuthAttributes?.role;
  const level = user?.alphaAuthAttributes?.levels[0]?.displayName;
  const llToL1SurveyLink = 'https://forms.gle/Xb9hNyv6R5nCoPGT9';
  const l2ToL3SurveyLink = 'https://forms.gle/ZBRcSemG2BBivxY9A';

  const { value: isSurveyViewed, toggleFunction: handleSurveyViewed } =
    useUserFlagContext()?.[IS_SURVEY_VIEWED];

  const isSurveyInFlight =
    new Date() >= new Date('2023-04-21') && new Date() <= new Date('2023-04-24');
  const surveyLink =
    level === LevelDisplayName.L2 || level === LevelDisplayName.L3
      ? l2ToL3SurveyLink
      : llToL1SurveyLink;
  const surveyElement = (
    <p>
      Please take a moment to fill out the{' '}
      <a href={surveyLink} target="_blank" rel="noopener noreferrer" onClick={handleSurveyViewed}>
        session survey
      </a>
      . Please complete the entire survey and click 'submit' at the end!
    </p>
  );
  return (
    <>
      {role !== Role.PARENT &&
        level !== LevelDisplayName.L4 &&
        !isSurveyViewed &&
        isSurveyInFlight && <Banner announcement={surveyElement} alertType="info" />}
    </>
  );
};

export default SurveyBanner;
