import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { merged_time_and_activity } from '../../@types/prisma';
import { Box, Typography } from '@mui/material';
import EmptyContent from '../EmptyContent';
import { aggregateByStudent } from './reducers';
import { GridColDef } from '@mui/x-data-grid';
import { styled, useTheme } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getOrderedUniqueSubjects } from './DailyActivityTable';
import { Student } from 'common/hooks/useStudentSelection';

const BaseCell = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

const columnMetadata = [
  {
    headerName: '2H Session',
    field: 'is_2hr_session',
    flex: 0.8,
    red: (val: string) => val === 'FALSE',
  },
  {
    headerName: '2H Streak Length',
    field: 'twohr_streak_length',
    flex: 0.8,
  },
  { headerName: 'Date', field: 'date' },
  { headerName: 'Student', field: 'student', flex: 1.5 },
  { headerName: 'Level', field: 'level', flex: 0.8 },
  { headerName: 'Grade', field: 'gradelevel', flex: 0.8 },
  { headerName: 'Subject', field: 'subject' },
  { headerName: 'App', field: 'app' },
  { headerName: 'Course', field: 'course' },
  { headerName: 'First Session', field: 'is_morning_session' },
  {
    headerName: 'Duration [M]',
    field: 'minutes_working',
  },
  { headerName: 'Total Q', field: 'total' },
  { headerName: 'Correct Q', field: 'correct' },
  { headerName: 'Mastered Levels', field: 'mastered_levels' },
  { headerName: 'Target Mastered Levels', field: 'target_master_units' },
  {
    headerName: 'Anti Patterns',
    field: 'anti_pattern_count',
    red: (val: string) => parseInt(val) > 0,
  },
  {
    headerName: 'Has Test Override',
    field: '',
    format: (row: merged_time_and_activity) => {
      if (row.has_edulastic === 'true') return 'Edulastic';
      if ((row.course || '').indexOf('Diagnostic') > -1) return 'Diagnostic';
      if (row.is_test_session === 'true') return 'Test';
      return '';
    },
    flex: 1.2,
    red: (val: string) => val === 'FALSE',
  },
  {
    headerName: 'Above Accuracy Threshold',
    field: 'is_above_accuracy_threshold',
    isBool: true,
    red: (val: string) => val === 'FALSE',
  },
  {
    headerName: 'Above Minutes Threshold',
    field: 'is_above_mins_threshold',
    isBool: true,
    red: (val: string) => val === 'FALSE',
  },
  {
    headerName: 'Above Mastery Threshold',
    field: 'is_above_mastery_threshold',
    red: (val: string) => val === 'FALSE',
  },
  {
    headerName: 'Has 2hr Override',
    field: 'has_2hr_override',
    format: (row: merged_time_and_activity) => {
      if (row.has_2hr_override === 'true') return 'TRUE';
      return 'FALSE';
    },
    flex: 1.2,
    red: (val: string) => val === 'TRUE',
  },
];

const DailyActivityTable2h = ({ learningDayData, isLoading, selectedStudents }: Props) => {
  const theme = useTheme();
  const uniqueSubjects = getOrderedUniqueSubjects(learningDayData);
  const dataByStudent = aggregateByStudent(learningDayData, selectedStudents);

  let rows: any[] = [];
  dataByStudent?.forEach((cur, index) => {
    uniqueSubjects.forEach((r) => {
      const subj = cur[r];
      if (!subj || typeof subj === 'string') return;

      subj.id = `${subj.student}-${r}`;
      rows.push(subj);
    });
  });

  rows = rows.filter((row) => Object.keys(row).length > 0) || [];

  const columns: GridColDef[] = columnMetadata.map((column) => ({
    field: column.field,
    headerName: column.headerName,
    flex: column.flex || 1,
    sortable: true,
    resizable: false,
    headerAlign: 'left',
    disableReorder: true,
    renderCell: (params) => {
      let value = params.value;
      if (column.format) value = column.format(params.row);
      if (column.isBool) value = value ? 'true' : 'false';
      if (value === 'true') value = 'TRUE';
      if (value === 'false') value = 'FALSE';

      const backgroundColor =
        column.red && column.red(value) ? theme.palette.error.main : 'rgba(65, 184, 212, 0.12)';

      return (
        <BaseCell
          sx={{
            backgroundColor,
            paddingLeft: '12px',
            paddingTop: '18px',
          }}
        >
          <Typography fontSize={'12px'} fontWeight={'600'}>
            {value}
          </Typography>
        </BaseCell>
      );
    },
  }));

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          paddingX: '24px',
          paddingBottom: '24px',
        }}
      >
        <DataGridPro
          autoHeight={true}
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableColumnMenu={true}
          rowSelection={false}
          onCellClick={() => {}}
          hideFooter
          slots={{
            noRowsOverlay: () => (
              <EmptyContent
                title="Oops!"
                description="No learning sessions found for this time period."
                sx={{
                  '& span.MuiBox-root': { height: 120 },
                }}
                img="/assets/illustrations/illustration_empty_content_custom.png"
              />
            ),
          }}
          sx={{
            borderRadius: '8px',
            '.MuiDataGrid-columnHeader': {
              backgroundColor: '#F9FAFB',
              fontSize: '12px',
              color: theme.palette.text.secondary,
              ['&:focus']: {
                outline: 'none',
              },
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontSize: '9px',
              whiteSpace: 'break-spaces',
              lineHeight: '16px',
            },
            '.MuiDataGrid-pinnedColumns--left': {
              boxShadow: 'none',
            },
            '.MuiDataGrid-cell': {
              padding: 0,
            },
            '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell:hover': {
              backgroundColor: '#e8f6fa', // required to make sure it's not transparent
              overflow: 'visible', // these two are...
              position: 'relative', // to allow an inherent z-index overflow for...
            },
            '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell:hover .MuiBox-root':
              {
                overflow: 'visible', // these
                position: 'absolute', // two
                top: 0,

                width: 'auto !important', // overrides parent height
                height: '100%', // helps fill smaller cells
                minWidth: '100%', // helps fill smaller cells

                // Requested to make it more obvious
                border: '2px solid rgba(65, 184, 212, 0.48)',
                paddingTop: '16px', // to avoid the border bump because I'm lazy
                paddingLeft: '10px', // to avoid the border bump because I'm lazy, 12px - 2px border
                paddingRight: '10px', // to avoid the border bump because I'm lazy, same as left
                backgroundColor: '#abdfec', // rgba(65, 184, 212, 0.36) as calculated, need something without alpha
              },
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }}
        />
      </TableContainer>
    </>
  );
};

type Props = {
  learningDayData?: merged_time_and_activity[] | undefined;
  selectedStudents: Student[];
  isLoading: boolean;
};
export default DailyActivityTable2h;
