import Iconify from '../../Iconify';
import ToggleButton from '@mui/material/ToggleButton';
import * as React from 'react';
import { ViewLevel } from '../types';
import { useRouter } from 'next/router';
import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  [`&.Mui-selected`]: {
    backgroundColor: 'inherit',
  },
  [`&.MuiToggleButton-root`]: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
}));

const ViewLevelToggleButton = ({ viewLevel, handleChange, selected }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { query } = router;
  const icon = {
    monthly: 'mdi:calendar-month',
    weekly: 'mdi:calendar-week',
    daily: 'mdi:calendar-today',
  }[viewLevel];

  const changeRoute = () => {
    switch (viewLevel) {
      case 'monthly':
        router.push({
          pathname: '/dashboard/daily-activity/month',
          query,
        });
        break;
      case 'weekly':
        router.push({
          pathname: '/dashboard/daily-activity/week',
          query,
        });
        break;
      case 'daily':
        router.push({
          pathname: '/dashboard/daily-activity',
          query,
        });
        break;
    }
  };

  const changeHandler = () => {
    handleChange();
    changeRoute();
  };

  const label = viewLevel.charAt(0).toUpperCase() + viewLevel.slice(1);
  return (
    <StyledToggleButton value={viewLevel} onChange={changeHandler} selected={selected}>
      <Box
        display={'flex'}
        alignItems={'center'}
        height={'100%'}
        sx={{
          borderBottom: selected ? '2px solid' : 'none',
          borderBottomColor: selected
            ? `${theme.palette.toggleButton.primary} !important`
            : 'inherit',
          padding: '11px',
        }}
      >
        <Iconify icon={icon} width={20} height={20} sx={{ marginRight: '11px' }} />
        {label}
      </Box>
    </StyledToggleButton>
  );
};

type Props = {
  viewLevel: ViewLevel;
  handleChange: () => void;
  selected: boolean;
};

export default ViewLevelToggleButton;
