import * as React from 'react';
import { useState } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewLevelToggleButton from './ViewLevelToggleButton';
import { ViewLevel } from '../types';
import { useTheme } from '@mui/material/styles';
import useSiteConfig from 'common/hooks/useSiteConfig';
import {
  ClickedViewType,
  CurrentViewType,
  amplitudeService,
  getCampusTypeByDomain,
} from 'common/analytics/amplitude';

const DailyActivityViewToggle = ({ inputViewLevel, isEmpty }: Props) => {
  const [viewLevel, setViewLevel] = useState<ViewLevel>(inputViewLevel);
  const theme = useTheme();
  const siteConfig = useSiteConfig();

  const handleViewLevelChange = (newViewLevel: ViewLevel) => {
    if (siteConfig?.data?.domain) {
      let currentView: CurrentViewType = 'Daily';
      let nextView: ClickedViewType = 'Daily';
      if (viewLevel === 'weekly') {
        currentView = 'Weekly';
      }
      if (viewLevel === 'monthly') {
        currentView = 'Monthly';
      }
      if (newViewLevel === 'weekly') {
        nextView = 'Weekly';
      }
      if (newViewLevel === 'monthly') {
        nextView = 'Monthly';
      }
      const campus = getCampusTypeByDomain(siteConfig.data.domain);
      amplitudeService.trackClickActivityViewPage(campus, currentView, nextView, isEmpty);
    }
    setViewLevel(newViewLevel);
  };

  return (
    <ToggleButtonGroup
      value={viewLevel}
      exclusive
      sx={{
        height: '48px',
        backgroundColor: theme.palette.grey[100],
        border: 'none',
      }}
    >
      {['monthly', 'weekly', 'daily'].map((level) => (
        <ViewLevelToggleButton
          key={level}
          viewLevel={level as ViewLevel}
          handleChange={() => handleViewLevelChange(level as ViewLevel)}
          selected={level === viewLevel}
        />
      ))}
    </ToggleButtonGroup>
  );
};

type Props = {
  inputViewLevel: ViewLevel;
  isEmpty?: boolean;
};

export default DailyActivityViewToggle;
