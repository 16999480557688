import DailyActivityNavContainer from './DailyActivityNavContainer';
import { Box, TextField } from '@mui/material';
import DailyActivityViewToggle from '../DailyActivityViewToggle/DailyActivityViewToggle';
import StudentMultiselect from '../../StudentMultiselect';
import React from 'react';
import PreviousNextButton from '../../PreviousNextButton';
import { Role } from '@common/@types/auth';
import useAuth from '../../../hooks/useAuth';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'common/@types/dayjs-custom';
import useSiteConfig from 'common/hooks/useSiteConfig';
import { amplitudeService, getCampusTypeByDomain } from 'common/analytics/amplitude';

const DailyViewNav = ({ date, setDate, isEmpty }: Props) => {
  const cur = date ? dayjs(date) : dayjs().subtract(1, 'day');

  const { user } = useAuth();
  const siteConfig = useSiteConfig();

  const changeSelectedDay = (direction: 'previous' | 'next') => {
    const newDate = direction === 'previous' ? cur.subtract(1, 'day') : cur.add(1, 'day');

    if (siteConfig?.data?.domain) {
      const campus = getCampusTypeByDomain(siteConfig.data.domain);
      amplitudeService.trackClickActivityViewPage(
        campus,
        'Daily',
        direction === 'previous' ? 'Previous Day' : 'Next Day',
        isEmpty
      );
    }
    setDate(newDate.format('YYYY-MM-DD'));
  };

  return (
    <DailyActivityNavContainer>
      <Box>
        <DailyActivityViewToggle isEmpty={isEmpty} inputViewLevel={'daily'} />
      </Box>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <PreviousNextButton handleClick={() => changeSelectedDay('previous')} type={'previous'} />
        <DatePicker
          openTo="day"
          views={['year', 'month', 'day']}
          label={'Select Date'}
          value={cur.toDate()}
          slots={{
            textField: (params: any) => (
              <TextField
                {...params}
                value={date ? cur.format('dddd, MMMM D, YYYY') : ''}
                margin="normal"
                sx={{ width: '300px' }}
              />
            ),
          }}
          onChange={(newDate) => {
            if (newDate) {
              setDate(dayjs(newDate).format('YYYY-MM-DD'));
            }
          }}
        />
        <PreviousNextButton handleClick={() => changeSelectedDay('next')} type={'next'} />
      </Box>
      {user?.alphaAuthAttributes?.role !== Role.STUDENT && (
        <StudentMultiselect enforceSingleSelect={false} />
      )}
    </DailyActivityNavContainer>
  );
};

type Props = {
  date: string;
  setDate: (date: string) => void;
  isEmpty?: boolean;
};

export default DailyViewNav;
