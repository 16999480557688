import { OptionProps } from 'react-select';
import { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const BaseOptionContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '85px',
  padding: '4px 10px',
  height: '30px',
  margin: '6px',
  border: '1px solid #DFE3E8',
  borderRadius: '8px',
  cursor: 'pointer',
});

const SelectedOptionContainer = styled(BaseOptionContainer)({
  backgroundColor: 'rgba(204, 242, 255, 0.5)',
  border: '1px solid #81CDE7',
  color: '#41B8D4',
});

const BorderedSelectOption = (
  props: PropsWithChildren<OptionProps<any, boolean, { label: string; options: any }>>
) => {
  const label = props?.data?.label;
  const isSelected = props?.isSelected;
  return isSelected ? (
    <SelectedOptionContainer>
      <Typography variant={'body2'}>{label}</Typography>
    </SelectedOptionContainer>
  ) : (
    <BaseOptionContainer onClick={() => props?.selectOption(props?.data)}>
      <Typography variant={'body2'}>{label}</Typography>
    </BaseOptionContainer>
  );
};

export default BorderedSelectOption;
