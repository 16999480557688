import { DateTime } from 'luxon';
import { useMemo } from 'react';

export const useSeenDailyActivityPage = () => {
  const pageSeen =
    localStorage.getItem('dailyActivityPageSeen') === DateTime.now().toFormat('yyyy-MM-dd');

  // Whether or not the user has seen the daily activity page as of the
  // beginning of their session
  const initialPageSeen = useMemo(() => pageSeen, []);

  return {
    pageSeen,
    initialPageSeen,
    setPageSeen: () => {
      localStorage.setItem('dailyActivityPageSeen', DateTime.now().toFormat('yyyy-MM-dd') ?? '');
    },
  };
};
