import Iconify from './Iconify';
import React from 'react';
import { IconButton } from '@mui/material';

const PreviousNextButton = ({ handleClick, type, disabled }: Props) => (
  <IconButton
    sx={{
      marginX: '30px',
      minWidth: '40px',
      width: '40px',
      height: '40px',
      border: '1px solid #DFE3E8 !important',
      borderRadius: '8px',
    }}
    onClick={handleClick}
    disabled={disabled === true}
  >
    <Iconify
      icon={type === 'previous' ? 'ooui:previous-ltr' : 'ooui:previous-rtl'}
      sx={{
        width: 20,
        height: 20,
      }}
    />
  </IconButton>
);

type Props = {
  handleClick: () => void;
  type: 'previous' | 'next';
  disabled?: boolean;
};
export default PreviousNextButton;
