import Select, {
  CSSObjectWithLabel,
  GroupProps,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import BorderedSelectOption from './BorderedSelectOption';
import InlineRenderedSelectGroup from './InlineRenderedSelectGroup';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

const GroupedOptionsSelect = ({
  options,
  onChange,
  selectedValue,
  isMulti,
  isSearchable,
  singleValueComponent,
  placeholder,
}: Props) => (
  <Select
    options={options}
    onChange={onChange}
    isMulti={isMulti === true}
    isSearchable={isSearchable === true}
    value={selectedValue}
    placeholder={placeholder || 'Select...'}
    closeMenuOnSelect={!isMulti}
    components={Object.assign(
      {},
      {
        Option: (
          props: PropsWithChildren<OptionProps<any, boolean, { label: string; options: any }>>
        ) => <BorderedSelectOption {...props} />,
        Group: (props: GroupProps<any, any, any> & { children?: ReactNode | undefined }) => (
          <InlineRenderedSelectGroup {...props} />
        ),
      },
      singleValueComponent &&
        singleValueComponent !== undefined && {
          SingleValue: (
            props: PropsWithChildren<SingleValueProps<any, false, { label: string; options: any }>>
          ) => singleValueComponent(props),
        }
    )}
    styles={Object.assign(
      {},
      {
        control: (provided: CSSObjectWithLabel) => ({
          ...provided,
          width: '520px',
        }),
        container: (provided: CSSObjectWithLabel) => ({
          ...provided,
        }),
        menu: (provided: CSSObjectWithLabel) => ({
          ...provided,
          zIndex: 9999,
        }),
      },
      singleValueComponent && {
        valueContainer: (provided: CSSObjectWithLabel) => ({
          ...provided,
          display: 'flex',
          padding: '2px 8px',
        }),
      }
    )}
  />
);

type Props = {
  options: {
    label: string;
    options: {
      label: string;
      value: string;
    }[];
  }[];
  onChange: (newSelectedValue: any) => void;
  selectedValue:
    | {
        label: string;
        value: string;
      }
    | {
        label: string;
        value: string;
      }[];
  isMulti?: boolean;
  isSearchable?: boolean;
  singleValueComponent?: (props: any) => ReactElement;
  placeholder?: string;
};

export default GroupedOptionsSelect;
