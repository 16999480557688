import { MultiValue, SingleValue } from 'react-select';
import React from 'react';
import GroupedOptionsSelect from './select/GroupedOptionsSelect';

interface StudentGroup {
  value: string;
  label: string;
}

const getAllSelectedValues = (inputValue: string | string[]) =>
  typeof inputValue === 'string' ? inputValue.split(',') : inputValue;

// this forces multiselect to be single select
const getLastSelectedValue = (inputValue: string | string[]) => {
  const valueList = typeof inputValue === 'string' ? inputValue.split(',') : inputValue;
  const lastElement = valueList[valueList.length - 1];
  return [lastElement];
};
const AlphabeticalGroupedSelect = ({
  options,
  selectedValues,
  setSelectedValues,
  isSingleSelect,
  placeholder,
}: Props) => {
  const groupedOptions = options.reduce((acc: any, curr: any) => {
    const firstLetter = curr.label[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(curr);
    return acc;
  }, {});
  const groupedOptionsArray = Object.keys(groupedOptions).map((key) => ({
    label: key,
    options: groupedOptions[key],
  }));
  const allowMultiselect = isSingleSelect === true ? false : true;
  const handleChange = (newSelectedValues: MultiValue<any> | SingleValue<any>) => {
    const values = allowMultiselect
      ? newSelectedValues?.map((value: any) => value.value)
      : [newSelectedValues?.value];
    setSelectedValues(
      !allowMultiselect ? getLastSelectedValue(values) : getAllSelectedValues(values)
    );
  };
  const defaultValue: { label: string; value: string }[] = options.filter((option) =>
    selectedValues.includes(option.value)
  );

  // Alphabetize the option groups
  groupedOptionsArray.sort((a, b) => {
    if (a.label < b.label) return -1;
    else return a.label > b.label ? 1 : 0;
  });

  // Alphabetize the names
  groupedOptionsArray.forEach((group) => {
    group.options.sort((a: StudentGroup, b: StudentGroup) => a.label.localeCompare(b.label));
  });

  return (
    <GroupedOptionsSelect
      isMulti={allowMultiselect}
      isSearchable
      options={groupedOptionsArray}
      onChange={handleChange}
      selectedValue={defaultValue}
      placeholder={placeholder}
    />
  );
};

type Props = {
  options: any[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  fullWidth?: boolean;
  isSingleSelect: boolean;
  placeholder?: string;
};

export default AlphabeticalGroupedSelect;
