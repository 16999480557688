import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import Iconify from '../Iconify';
import * as React from 'react';
import { student_antipatterns } from '../../@types/prisma';
import { styled } from '@mui/material/styles';

const BorderedBox = styled(Box)(({ theme }) => ({
  border: theme.palette.grey[300] + ' 1px solid',
  padding: '16px',
  borderRadius: '8px',
  margin: '16px',
  height: '200px',
  overflow: 'auto',
}));

const AntipatternBody = ({ antipattern }: Props) => {
  const evidenceLinkList = antipattern.links?.split(',');
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{ backgroundColor: theme.palette.grey[200], borderRadius: '8px', padding: '12px 16px' }}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'56px'}
          sx={{ backgroundColor: theme.palette.grey[600], borderRadius: '8px', color: 'white' }}
        >
          <Iconify
            icon={antipattern.icon ? antipattern.icon : 'carbon:checkmark-outline-error'}
            width={32}
            height={32}
          />
          <Typography variant={'h5'} sx={{ textAlign: 'center', marginLeft: '16px' }}>
            {antipattern.antipattern}
          </Typography>
        </Box>
        {evidenceLinkList && evidenceLinkList.length > 0 && (
          <Stack direction={'column'} sx={{ marginTop: '16px', marginBottom: '40px' }}>
            <Typography variant={'body2'} align={'center'}>
              <strong>See how you got this anti-pattern:</strong>
            </Typography>
            <Box sx={{ marginTop: '10px' }} display={'flex'} justifyContent={'center'}>
              {evidenceLinkList.map((link, index) => (
                <Typography variant={'body2'} key={index}>
                  <a
                    href={link}
                    target={'_blank'}
                    rel={'noreferrer'}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant={'contained'}>{`Screenshot ${index + 1}`}</Button>
                  </a>
                </Typography>
              ))}
            </Box>
          </Stack>
        )}
      </Box>
      <Box width={'100%'} display={'flex'} alignContent={'center'}>
        {antipattern.trigger && (
          <BorderedBox sx={{ flexGrow: 1, width: 0 }}>
            <Typography variant={'body2'}>
              <strong>Why was this anti-pattern triggered?</strong>
            </Typography>
            <Typography
              variant={'body2'}
              sx={{ paddingY: '10px', ul: { marginLeft: '1em' } }}
              dangerouslySetInnerHTML={{
                __html: antipattern.trigger,
              }}
            />
          </BorderedBox>
        )}
        {antipattern.fix && (
          <BorderedBox sx={{ flexGrow: 1, width: 0 }}>
            <Typography variant={'body2'}>
              <strong>How can you avoid it?</strong>
            </Typography>
            <Typography variant={'body2'}>{antipattern.fix}</Typography>
          </BorderedBox>
        )}
      </Box>
    </Box>
  );
};

type Props = {
  antipattern: student_antipatterns;
};

export default AntipatternBody;
