import { GroupProps } from 'react-select';
import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

const InlineRenderedSelectGroup = (
  props: GroupProps<any, any, any> & { children?: ReactNode | undefined }
) => (
  <Box sx={{ margin: '16px' }}>
    <Typography variant={'body2'} sx={{ color: '#637381' }}>
      {props.label}
    </Typography>
    <Box display={'flex'} flexWrap={'wrap'}>
      {props?.children}
    </Box>
  </Box>
);

export default InlineRenderedSelectGroup;
