import { useSeenDailyActivityPage } from '@common/hooks/useSeenDailyActivityPage';
import useSiteConfig from '@common/hooks/useSiteConfig';
import { Box, Button, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { styled, useTheme } from '@mui/material/styles';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect } from 'react';
import { merged_time_and_activity, roster_subject_cards, student_coaching } from '../../@types/prisma';
import EmptyContent from '../EmptyContent';
import RadialGaugeLearningSessionFormatter from './formatters/learningSession/RadialGaugeLearningSessionFormatter';
import { aggregateByStudent } from './reducers';
import { AppSubjectGradeThreshold } from 'common/utils/amplify/getMasteryThresholds';
import { Student } from 'common/hooks/useStudentSelection';

const BaseCell = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

function updateMasteryUnits(
  app_thresholds: AppSubjectGradeThreshold[] | undefined,
  learningDayData: merged_time_and_activity[] | undefined
) {
  if (!app_thresholds || !learningDayData) return;

  const appThresholdMap = new Map<string, number>();
  app_thresholds.forEach(threshold => {
    const key = `${threshold.app.toLowerCase()}-${threshold.subject}-${threshold.campus_id}`;
    appThresholdMap.set(key, threshold.target_mastery_units);
  });

  learningDayData.forEach(item => {
    if (item.target_master_units.includes(' 0')) {
      const targetMasterUnitsArray = item.target_master_units.split(',').map(val => {
        const [app, units] = val.split(':').map(str => str.trim());
        if (units === '0') {
          const key = `${app.toLowerCase()}-${item.subject}-${parseInt(item.campus_and_student_id?.split('-')[0] || '0')}`;
          if (appThresholdMap.has(key)) {
            return `${app}: ${appThresholdMap.get(key)}`;
          }
        }
        return val;
      });
      item.target_master_units = targetMasterUnitsArray.join(',');
    }
  });
}

const genMapKey = (studentId: string, subject: string):string => {
  const [campusId, student_id] = studentId.split('-');
  return `${campusId}-${student_id}-${subject}`;
}
export function getOrderedUniqueSubjects(
  learningDayData: merged_time_and_activity[] | undefined,
  rosteredSubjects?: roster_subject_cards[]
) {
  const idToNameMap = new Map<string, string>();
  rosteredSubjects?.forEach(item => {
    idToNameMap.set(item.studentId.split('-')[1], item.studentName);
  });
  const uniqueSubjects: Set<string> = new Set();
  const learningSubjectToAppsMap: Record<string, string[]> = {};
  const rosteredSubjectToAppsMap: Record<string, string[]> = {};

  // Helper function to update the maps
  const updateSubjectToAppsMap = (map: Record<string, string[]>, key: string, apps: string[]) => {
    map[key] = [...(map[key] || []), ...apps];
  };

  // Get unique subject for all learning data
  learningDayData?.forEach(item => {
    uniqueSubjects.add(item.subject);
    const key = genMapKey(item.campus_and_student_id as string, item.subject);
    updateSubjectToAppsMap(learningSubjectToAppsMap, key, item?.app?.split(', ') || []);
  });
  // Get unique subject for all rostered subjects
  rosteredSubjects?.forEach(item => {
    item.subjects?.forEach(subItem => {
      uniqueSubjects.add(subItem.subject);
      const key = genMapKey(item.studentId, subItem.subject);
      updateSubjectToAppsMap(rosteredSubjectToAppsMap, key, subItem?.app?.split(', ') || []);
    });
  });

  Object.keys(rosteredSubjectToAppsMap).forEach(key => {
    const [campusId, studentId, subject] = key.split('-');
    const studentName = idToNameMap.get(studentId);
    // If the learning data doesn't have the subject, push empty data
    if (!learningSubjectToAppsMap[key]) {
      pushEmptyData(learningDayData, studentName || "", `${campusId}-${studentId}`, subject, rosteredSubjectToAppsMap[key]);
      learningSubjectToAppsMap[key] = rosteredSubjectToAppsMap[key];
    } else {
      // If the learning data has the subject but the apps are different, push empty app data
      const appsinRostered = new Set<string>(rosteredSubjectToAppsMap[key].map(app => app.toLowerCase()));
      const appsinLearning = new Set<string>(learningSubjectToAppsMap[key].map(app => app.toLowerCase()));
      const missingApps = Array.from(appsinRostered).filter(app => !appsinLearning.has(app));
      if (missingApps.length > 0) {
        learningDayData?.forEach(item => {
          if (item.subject === subject && item.student_id === studentId) {
            const missingAppsString = missingApps.join(', ');
            const missingAppsMasteredLevels = missingApps.map(app => `${app}: 0`).join(',');
            item.app = item.app ? `${item.app}, ${missingAppsString}` : missingAppsString;
            item.mastered_levels = item.mastered_levels ? `${item.mastered_levels}, ${missingAppsMasteredLevels}` : missingAppsMasteredLevels;
            item.target_master_units = item.target_master_units ? `${item.target_master_units}, ${missingAppsMasteredLevels}` : missingAppsMasteredLevels;
          }
        });
      }
    }
  });

  return Array.from(uniqueSubjects).sort();
}

const pushEmptyData = (
  learningDayData: merged_time_and_activity[] | undefined,
  student: string,
  campusAndStudentId: string,
  subject: string,
  app: string[]
) => {
  learningDayData?.push({
    date: '',
    student,
    campus_and_student_id: campusAndStudentId,
    subject,
    app: app.join(', '),
    course: '',
    correct_questions_per_hour: 0,
    perc_questions_correct: 0,
    minutes_working: 0,
    correct: 0,
    total: 0,
    has_antipattern: false,
    has_edulastic: 'false',
    is_test_session: 'false',
    id: '',
    is_2hr_session: 'false',
    is_above_mastery_threshold: 'false',
    mastered_levels: app.map(item=>`${item}: 0`).join(','),
    target_master_units: app.map(item=>`${item}: 0`).join(','),
    student_id: campusAndStudentId.split('-')[1],
    has_2hr_override: 'false',
    override_explanation: '',
    is_above_mins_threshold: true,
    is_above_accuracy_threshold: false,
    anti_pattern_count: '0',
    twohr_streak_length: 0,
  });
};

const DailyActivityTable = ({
  masteryUnits,
  learningDayData,
  onClickCell,
  onCoachingButtonClicked,
  clipReviewCoachingData,
  isLoading,
  selectedStudents,
  rosteredSubjects,
}: Props) => {
  const theme = useTheme();
  const uniqueRosteredSubjects = Array.from(new Set(rosteredSubjects.flatMap(student => student.subjects.map(subject => subject.subject))));
  const studentIds = selectedStudents.map(item => item.id);
  learningDayData = learningDayData?.filter(item => studentIds.includes(item.student_id as string));
  const uniqueSubjects = getOrderedUniqueSubjects(learningDayData, rosteredSubjects);
  const dataByStudent = aggregateByStudent(learningDayData, selectedStudents);
  useEffect(() => {
    if (!masteryUnits || !learningDayData) return;
    updateMasteryUnits(masteryUnits as AppSubjectGradeThreshold[], learningDayData);
  }, [masteryUnits, learningDayData]);
  const rows: GridRowsProp =
    dataByStudent
      ?.map((cur, index) => {
        const subjects: Record<string, any> = {};
        uniqueSubjects.forEach((subject) => {
          const subjectData = cur[subject];
          if (subjectData) {
            subjects[subject] = cur[subject];
          }
        });
        if (Object.keys(subjects).length > 0) {
          return {
            id: `${cur.student}|${index}`,
            student: cur.name,
            ...subjects,
          };
        }
        return {};
      })
      .filter((row) => Object.keys(row).length > 0) || [];

  const staticColumns: GridColDef[] = [
    {
      field: 'student',
      headerName: 'Student',
      minWidth: 200,
      flex: 1,
      sortable: true,
      resizable: false,
      headerAlign: 'left',
      disableReorder: true,
      renderCell: (params) => (
        <BaseCell
          sx={{
            backgroundColor: 'rgba(65, 184, 212, 0.12)',
            paddingLeft: '24px',
            paddingTop: '18px',
          }}
        >
          <Typography fontSize={'14px'} fontWeight={'600'}>
            {params.value}
          </Typography>
        </BaseCell>
      ),
    },
  ];

  const dynamicColumns: GridColDef[] = uniqueSubjects.map((subject) => ({
    field: subject,
    headerName: subject,
    flex: 2,
    minWidth: 260,
    sortable: false,
    resizable: false,
    headerAlign: 'center',
    disableReorder: true,
    renderCell: (params) => {
      const learningSession: merged_time_and_activity = params.row[params.field];
      const hasCoaching = clipReviewCoachingData?.find(
        (c: student_coaching) =>
          c.subject === learningSession?.subject &&
          c.studentId === learningSession?.campus_and_student_id &&
          c.date === learningSession?.date &&
          c.coaching?.length > 0
      );
      return (
        <BaseCell
          sx={{
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {learningSession && (
            <RadialGaugeLearningSessionFormatter
              learningSession={learningSession as merged_time_and_activity}
              onClick={() => {
                onClickCell(
                  learningSession.subject,
                  learningSession.app,
                  learningSession.campus_and_student_id as string
                );
              }}
              isLoading={isLoading}
            >
              <div
                style={{
                  minHeight: '50px',
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {hasCoaching && (
                  <Button
                    variant="contained"
                    component="div"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onCoachingButtonClicked(
                        learningSession.subject,
                        learningSession.campus_and_student_id as string
                      );
                    }}
                    sx={{
                      left: '0',
                      right: '0',
                      marginX: 'auto',
                      maxWidth: '100px',
                      marginTop: '12px',
                      marginBottom: '6px',
                      padding: '6px',
                    }}
                  >
                    Coaching
                  </Button>
                )}
              </div>
            </RadialGaugeLearningSessionFormatter>
          )}
        </BaseCell>
      );
    },
  }));
  const columns = [...staticColumns, ...dynamicColumns];

  // The logic below allows to show the "My Required Learning" button only in certain
  // contexts. Note that `initialPageSeen` is memoized, so we can call `setPageSeen`
  // and not have it immediately disappear.
  const { initialPageSeen, setPageSeen } = useSeenDailyActivityPage();
  const { data } = useSiteConfig();
  const router = useRouter();

  // We want to show only if:
  const showMyRequiredLearning =
    data?.themeOptions?.showMyRequiredLearningCTA && // - the theme asks us to
    !initialPageSeen && // - the user hasn't seen this page yet
    !isLoading && // - the data is loaded
    rows.length === 0; // - there is no data for the default days

  // Clicking the button just points the user to the recommendations page
  const handleMyRequiredLearningClick = () => {
    router.push('/learn/recommendations');
  };

  // Once the page is loaded, ensure we've marked it as seen
  useEffect(() => {
    if (!initialPageSeen) setPageSeen();
  }, [initialPageSeen]);
  // end of My Required Learning button logic

  const isStillLoading = selectedStudents.length > 0 && (isLoading || rosteredSubjects?.length===0 || !learningDayData || learningDayData.length < uniqueRosteredSubjects.length)
  return (
    <TableContainer
      component={Paper}
      sx={{
        paddingX: '24px',
        paddingBottom: '24px',
      }}
    >
      <DataGridPro
        rows={isStillLoading ? rows.map((item)=>({id: item.id, student: item.student})) : rows}
        columns={isStillLoading? staticColumns: columns}
        loading={isStillLoading}
        rowHeight={400}
        autoHeight={true}
        disableColumnMenu={true}
        rowSelection={false}
        onCellClick={() => {}}
        pinnedColumns={{ left: ['student'] }}
        hideFooter
        slots={{
          noRowsOverlay: () => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <EmptyContent
                title="Oops!"
                description="No learning data currently available for this time period."
                sx={{
                  '& span.MuiBox-root': { height: 120 },
                }}
                img="/assets/illustrations/illustration_empty_content_custom.png"
              />

              {showMyRequiredLearning && (
                <Button variant="contained" onClick={handleMyRequiredLearningClick}>
                  My Required Learning
                </Button>
              )}
            </div>
          ),
        }}
        sx={{
          borderRadius: '8px',
          '.MuiDataGrid-columnHeader': {
            backgroundColor: '#F9FAFB',
            color: theme.palette.text.secondary,
            ['&:focus']: {
              outline: 'none',
            },
          },
          '.MuiDataGrid-pinnedColumns--left': {
            boxShadow: 'none',
          },
          '.MuiDataGrid-cell': {
            padding: 0,
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        }}
      />
    </TableContainer>
  );
};

type Props = {
  masteryUnits: AppSubjectGradeThreshold[] | undefined;
  learningDayData?: merged_time_and_activity[] | undefined;
  selectedStudents: Student[];
  onClickCell: (subject: string, app: string, studentId: string) => void;
  onCoachingButtonClicked: (subject: string, studentId: string) => void;
  clipReviewCoachingData?: student_coaching[];
  rosteredSubjects: roster_subject_cards[];
  isLoading: boolean;
};
export default DailyActivityTable;
