import AlphabeticalGroupedSelect from './AlphabeticalGroupedSelect';
import React from 'react';
import useStudentList from '../hooks/useStudentList';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { setSelectedStudents } from '../redux/slices/dashboardFilterSelections';

const getDefaultSelectedStudents = (
  studentNames: any,
  contextSelectedStudents: string[],
  isSingleSelect: boolean
) => {
  if (contextSelectedStudents) {
    return isSingleSelect ? [contextSelectedStudents[0]] : contextSelectedStudents;
  }
  return studentNames?.[0]?.value ? [studentNames?.[0].value] : [];
};

const StudentMultiselect = ({
  enforceSingleSelect,
  visibilityFunction: blacklistFunction,
}: Props) => {
  const selectedStudents = useAppSelector(
    (state) => state.dashboardFilterSelections.selectedStudents
  );
  const dispatch = useAppDispatch();
  const isSingleSelect = enforceSingleSelect === false ? false : true;
  const studentList = useStudentList();
  const studentNames: any[] =
    (blacklistFunction ? studentList?.filter(blacklistFunction) : studentList)
      ?.map((student: any) => ({ value: student.student_id, label: student.name }))
      .filter((student: any) => student.value && student.label) || [];
  const selectedStudentsLocal = getDefaultSelectedStudents(
    studentNames,
    selectedStudents,
    isSingleSelect
  );

  return (
    <AlphabeticalGroupedSelect
      options={studentNames || []}
      selectedValues={selectedStudentsLocal}
      setSelectedValues={(values) => dispatch(setSelectedStudents(values))}
      isSingleSelect={isSingleSelect}
      placeholder={'Select students...'}
    />
  );
};

type filterFn = (student: any) => boolean;
type Props = {
  enforceSingleSelect?: boolean;
  visibilityFunction?: filterFn;
};

export default StudentMultiselect;
