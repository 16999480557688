import { merged_time_and_activity } from "common/@types/prisma";
import { RealtimeDailyActivityItem } from "./amplify/getRealtimeDailyActivity";

const default_values = {
  is_test_session: 'false',
  twohr_streak_length: 0,
  has_2hr_override: 'false',
  override_explanation: '',
};

export const switchRealtimeMetrics = (
  realtimeData: RealtimeDailyActivityItem[],
  learningDayData: merged_time_and_activity[]
): merged_time_and_activity[] => {
	if(realtimeData.length === 0) return learningDayData;

  const realtimeMap = new Map(
    realtimeData.map((item) => [`${item.subject}-${item.campus_and_student_id}`, item])
  );

  const updatedData: merged_time_and_activity[] = learningDayData.map((learningItem) => {
    const key = `${learningItem.subject}-${learningItem.campus_and_student_id}`;
    const matchingRealtimeItem = realtimeMap.get(key);

    if (!matchingRealtimeItem) return learningItem;
    return {
      ...learningItem,
      ...matchingRealtimeItem,
      student: learningItem?.student ?? matchingRealtimeItem.student,
      is_2hr_session:
        learningItem.is_2hr_session === 'true' ? 'true' : matchingRealtimeItem.is_2hr_session,
    };
  });

  // Add new items from realtime data
  realtimeMap.forEach((realtimeItem, key) => {
    if (updatedData.some((item) => `${item.subject}-${item.campus_and_student_id}` === key)) return;
    updatedData.push({
      ...default_values,
      ...realtimeItem,
    });
  });
  return updatedData;
};