import { Box, Card } from '@mui/material';
import * as React from 'react';
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import { CarouselArrowIndex } from '../carousel';
import BasicModal from './BasicModal';

const PaginatedModal = ({ open, onClose, children }: Props) => {
  const theme = useTheme();
  const carouselRef = useRef<Slider | null>(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };
  return (
    <BasicModal open={open} onClose={onClose}>
      <Box width={'100%'}>
        <Card sx={{ padding: '25px' }}>
          <Slider ref={carouselRef} {...settings}>
            {children}
          </Slider>

          <CarouselArrowIndex
            index={currentIndex}
            total={React.Children.count(children)}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </Card>
      </Box>
    </BasicModal>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export default PaginatedModal;
