import { Student } from 'common/utils/amplify/getStudents';

export const getStudentNameById = (data: Student[] | undefined, studentId: string) => {
  if (data) {
    const studentName = data?.find((student) => student.student_id === studentId)?.name;
    return studentName;
  }
};

export const getLevelById = (data: Student[] | undefined, studentId: string) => {
  if (data) {
    const level = data?.find((student) => student.student_id === studentId)?.alpha_level;
    return level;
  }
};

export const getSessionWeekKey = (session: string, week: string) => `${session}.${week}`;
export const decodeSessionWeekKey = (key: string) => {
  const [session, week] = key.split('.');
  return { session, week };
};
