import { Alert, AlertColor } from '@mui/material';

const Banner = ({ announcement, alertType }: Props) => (
  <span>
    {announcement && alertType && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert severity={alertType} sx={{ width: '50%' }}>
          {announcement}
        </Alert>
      </div>
    )}
  </span>
);

type Props = {
  announcement?: JSX.Element;
  alertType?: AlertColor;
};

export default Banner;
