import { dateStringToLocalTime } from '../../utils/formatTime';
import { merged_time_and_activity } from '../../@types/prisma';
import { getSessionWeekKey } from './util';
import { Student } from 'common/hooks/useStudentSelection';

export const doesAggregationHaveData = (data: Record<any, any>[] | undefined) =>
  data ? data.some((el) => Object.keys(el).length > 1) : false;
export const aggregateByStudent = (
  data: merged_time_and_activity[] | undefined,
  studentList: Student[]
) => {
  const dataByStudent = data?.reduce<Record<string, string | merged_time_and_activity>[]>(
    (acc, curr) => {
      const student = acc.find((el) => el.student === curr.student_id);
      if (!student) {
        acc.push({
          student: curr.student_id!,
          name: curr.student,
          [curr.subject]: curr,
        });
      } else {
        if (student[curr.subject]) {
          if (typeof student[curr.subject] !== 'string') {
            student[curr.subject] = curr;
          }
        } else {
          student[curr.subject] = curr;
        }
      }

      return acc;
    },
    studentList.map((student) => ({
      student: student.id!,
      name: student.name!,
    }))
  );
  return dataByStudent;
};

export const aggregateStudentWeeklyMetrics = (
  selectedStudents: string[],
  data?: merged_time_and_activity[]
) =>
  data?.reduce(
    (acc: { student: string; count_2hr_sessions: number }[], curr) => {
      const student = acc.find((el) => el.student === curr.student);
      if (!student) {
        acc.push({
          student: curr.student,
          count_2hr_sessions: curr.is_2hr_session === 'true' ? 1 : 0,
        });
      } else {
        student.count_2hr_sessions += curr.is_2hr_session === 'true' ? 1 : 0;
      }

      return acc;
    },
    selectedStudents.map((student) => ({
      student: student,
      count_2x_sessions: 0,
      count_2hr_sessions: 0,
      count_2x_minutes: 0,
    }))
  );

export const aggregateByWeekdayAndStudent = (
  selectedStudents: string[],
  data?: merged_time_and_activity[]
) =>
  data?.reduce<Record<string, string | merged_time_and_activity[]>[]>(
    (acc, curr) => {
      const student = acc.find((el) => el.student === curr.student);
      const date = dateStringToLocalTime(curr.date);

      const dayOfWeekString: string = new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
      }).format(date);

      if (student) {
        if (!student.studentId) {
          student.studentId = curr.campus_and_student_id || '';
        }
        if (student[dayOfWeekString]) {
          if (typeof student[dayOfWeekString] !== 'string') {
            (student[dayOfWeekString] as merged_time_and_activity[]).push(curr);
          }
        } else {
          student[dayOfWeekString] = [curr];
        }
      }

      return acc;
    },
    selectedStudents.map((student) => ({
      student,
    }))
  );

export const groupCalendarData = (schoolYearDateData?: any[]) =>
  schoolYearDateData?.reduce((acc: any, curr: any) => {
    const getSessionLabel = (current: any) => `Session ${current.session}`;
    if (curr.week_num && curr.session) {
      const value = {
        label: `Week ${curr.week_num}`,
        value: getSessionWeekKey(curr.session, curr.week_num),
      };
      if (!acc[getSessionLabel(curr)]) {
        acc[getSessionLabel(curr)] = [value];
      } else {
        acc[getSessionLabel(curr)].push(value);
      }
    }
    return acc;
  }, {});
