import { Box, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { student_coaching } from '../../@types/prisma';
import { styled } from '@mui/material/styles';
import { parse, format } from 'date-fns';
import { MuiMarkdown, getOverrides } from 'mui-markdown';

const BoundedBox = styled(Box)(({ theme }) => ({
  margin: '8px',
  height: 'auto',
  overflow: 'auto',
  maxHeight: '75vh',
  fontSize: '12px',
}));

function convertDateFormat(dateString: string): string {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(parsedDate, 'EEEE, MMMM dd, yyyy');
}

const CoachingBody = ({ coaching, title, date }: Props) => {
  const theme = useTheme();
  return (
    <Box width="100%">
      <Typography variant="h4">{title}</Typography>
      <Typography variant="h5">{convertDateFormat(date)}</Typography>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[200],
          borderRadius: '8px',
          padding: '12px 16px',
          marginTop: '8px',
        }}
      >
        <Box width={'100%'} display={'flex'} alignContent={'center'}>
          <BoundedBox sx={{ flexGrow: 1, marginTop: '-10px', width: 0 }}>
            <MuiMarkdown
              options={{
                overrides: {
                  ...getOverrides(), // This will keep the other default overrides.
                  p: {
                    component: 'p',
                    props: {
                      style: {
                        color: '',
                        'margin-top': '10px',
                        'margin-bottom': '10px',
                        fontSize: '14px',
                      },
                    },
                  },
                  strong: {
                    component: 'strong',
                    props: {
                      style: {
                        'padding-left': '0.5em',
                      },
                    },
                  },
                  li: {
                    component: 'li',
                    props: {
                      style: {
                        color: '',
                        'margin-left': '20px',
                        display: 'list-item',
                        'list-style-item': 'square',
                      },
                    },
                  },
                  a: {
                    // Override for anchor tags // AI-GEN - Cursor - GPT4
                    component: 'a', // AI-GEN - Cursor - GPT4
                    props: {
                      target: '_blank', // Open links in a new tab // AI-GEN - Cursor - GPT4
                      rel: 'noopener noreferrer', // Security measure for links to open in a new tab // AI-GEN - Cursor - GPT4
                    }, // AI-GEN - Cursor - GPT4
                  }, // AI-GEN - Cursor - GPT4
                },
              }}
            >
              {coaching.coaching.replace('\n🎯', '<br/>🎯')}
            </MuiMarkdown>
          </BoundedBox>
        </Box>
      </Box>
    </Box>
  );
};

type Props = {
  coaching: student_coaching;
  title: string;
  date: string;
};

export default CoachingBody;
