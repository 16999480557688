import { Role } from '@common/@types/auth';
import Page from '@common/components/Page';
import SurveyBanner from '@common/components/SurveyBanner';
import DailyViewNav from '@common/components/daily-activity/nav/DailyViewNav';
import ToggleWrapper from '@common/components/daily-activity/toggle-wrapper';
import { useAppSelector } from '@common/hooks/useAppSelector';
import useAuth from '@common/hooks/useAuth';
import Layout from '@common/layouts';
import { setDate } from '@common/redux/slices/dashboardFilterSelections';
import { clearTandaCache } from '@common/utils/amplify/getTimeAndActivity';
import { Box, Button, ToggleButton } from '@mui/material';
import { merged_time_and_activity } from 'common/@types/prisma';
import { resetDateCacheAndLoadingPromise } from 'common/utils/amplify/getAllDays';
import { clearRealtimeApiCache } from 'common/utils/amplify/getRealtimeDailyActivity';
import { clearStudentList } from 'common/utils/amplify/getStudents';
import { useRouter } from 'next/router';
import React from 'react';
import LearningDaySummary from '../../../sections/daily-activity/LearningDaySummary';

DailyActivityPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout pageName="Daily">{page}</Layout>;
};

export default function DailyActivityPage() {
  const router = useRouter();
  const debugURLValue = router.query['debug'] === 'true';
  const [debug, setDebug] = React.useState(debugURLValue);
  const handleDebugChange = (event: React.SyntheticEvent, value: any) => {
    setDebug((event.target as HTMLButtonElement).value === 'true');
  };

  // Used to force a reload of the component - if this param changes via setCacheTimestamp,
  // it'll trigger a reload. Use this in the `key` parameter to any component that needs to
  // reload.
  const [cacheTimestamp, setCacheTimestamp] = React.useState(new Date().getTime());
  const [empty, setEmpty] = React.useState<boolean | undefined>(undefined);

  const { date: selectedDate } = useAppSelector(
    (state) => state.dashboardFilterSelections.dateDetails
  );

  const { user } = useAuth();
  const role = user?.alphaAuthAttributes?.role;

  const onDataLoad = (data: merged_time_and_activity[] | undefined) => {
    setEmpty(data == null || data?.length === 0);
  };

  const clearCaches = () => {
    clearTandaCache();
    resetDateCacheAndLoadingPromise();
    clearStudentList();
    setCacheTimestamp(new Date().getTime());
    clearRealtimeApiCache();
  };

  return (
    <Page title="Daily Activity">
      <SurveyBanner />
      <Box sx={{ display: 'table', textAlign: 'right', marginLeft: 'auto', marginRight: '24px' }}>
        <ToggleWrapper
          value={debug.toString()}
          label={'Advanced debugging:'}
          clickHandler={handleDebugChange}
        >
          <ToggleButton value="true">On</ToggleButton>
          <ToggleButton value="false">Off</ToggleButton>
        </ToggleWrapper>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}
      >
        <DailyViewNav date={selectedDate} setDate={setDate} isEmpty={empty} />
        <LearningDaySummary
          date={selectedDate}
          debugView={debug}
          key={cacheTimestamp}
          onLoad={onDataLoad}
        />
        {role === Role.ADMIN && debug ? (
          <Box sx={{ textAlign: 'right', marginRight: '24px' }}>
            <Button sx={{ paddingY: '12px' }} variant={'outlined'} onClick={clearCaches}>
              Clear Cache
            </Button>
          </Box>
        ) : null}
      </Box>
    </Page>
  );
}
