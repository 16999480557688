import {
  merged_time_and_activity,
  roster_subject_cards,
  student_antipatterns,
  student_coaching,
} from '@common/@types/prisma';
import AntipatternBody from '@common/components/daily-activity/AntipatternBody';
import CoachingBody from '@common/components/daily-activity/CoachingBody';
import DailyActivityTable from '@common/components/daily-activity/DailyActivityTable';
import DailyActivityTable2h from '@common/components/daily-activity/DailyActivityTable2h';
import PaginatedModal from '@common/components/modal/PaginatedModal';
import { useAppSelector } from '@common/hooks/useAppSelector';
import useStudentSelection from '@common/hooks/useStudentSelection';
import { trpc } from '@common/utils/trpcAmplify';
import { Box } from '@mui/material';
import { Role } from 'common/@types/auth';
import dayjs from 'common/@types/dayjs-custom';
import { amplitudeService, getCampusTypeByDomain } from 'common/analytics/amplitude';
import BasicModal from 'common/components/modal/BasicModal';
import useAuth from 'common/hooks/useAuth';
import useSiteConfig from 'common/hooks/useSiteConfig';
import { switchRealtimeMetrics } from 'common/utils/realtimeActivityMetrics';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Values that are missing in realtime data

const LearningDaySummary: React.FC<Props> = ({ date, debugView, onLoad }: Props) => {
  const [selectedAntipatternData, setSelectedAntipatternData] = useState<student_antipatterns[]>(
    []
  );
  const [selectedCoachingData, setSelectedCoachingData] = useState<student_coaching[]>([]);

  // We need the studentIds for loading the data, so fetch it directly here
  // Instead of waiting for studentIds from useStudentSelection
  const studentIds: string[] | null = useAppSelector(
    (state) => state.dashboardFilterSelections.selectedStudents
  );

  const students = useStudentSelection();
  const alphaIds = students
    .filter((student) => student?.id && studentIds?.includes(student?.id))
    .map((student) => student?.level);

  const siteConfig = useSiteConfig();
  const selectedStudents = students
    .filter((student: any) => studentIds?.includes(student.id))
    .map((el) => ({ ...el, id: el.id?.split('-')[1] }));
  const { user } = useAuth();
  const role = user?.alphaAuthAttributes?.role;

  const getStudentNameById = useCallback(
    (id: string) => {
      const student = students.find((student: any) => student.id === id);
      return student?.name;
    },
    [students]
  );

  const {
    data: learningDayData,
    isLoading,
    fetchStatus,
  } = trpc.mergedTimeAndActivity.get.useQuery(
    {
      fromDate: date,
      toDate: date,
      studentIds: studentIds ?? [],
    },
    {
      enabled: studentIds?.length > 0,
    }
  );

  const isToday = date === dayjs().format('YYYY-MM-DD');

  const {
    data: realtimeDailyActivityData,
    isLoading: isLoadingRealtime,
    fetchStatus: realtimeFetchStatus,
  } = trpc.getStudentRealtimeActivity.get.useQuery(
    {
      startDate: date,
      endDate: date,
      students: studentIds ?? [],
      alphaLevel: alphaIds ?? [],
    },
    {
      enabled:
        studentIds?.length > 0 &&
        alphaIds.length > 0 &&
        alphaIds.length === studentIds.length &&
        isToday,
      pollInterval: 1000 * 60 * 2, // 2 minutes in milliseconds
    }
  );
  const isStillFetching =
    (isLoading ||
      fetchStatus === 'preparing' ||
      (isToday && (isLoadingRealtime || realtimeFetchStatus === 'preparing'))) &&
    studentIds.length > 0 &&
    !realtimeDailyActivityData;

  const learningDayDataLatest = useMemo(() => {
    if (!isToday) {
      return learningDayData;
    }
    if (!learningDayData || !realtimeDailyActivityData) {
      return undefined;
    }
    return switchRealtimeMetrics(realtimeDailyActivityData, learningDayData);
  }, [learningDayData, realtimeDailyActivityData, isToday]);

  useEffect(() => {
    onLoad(learningDayDataLatest);
  }, [learningDayDataLatest]);

  const { data: antipatternData } = trpc.antipatterns.get.useQuery(
    {
      fromDate: date,
      toDate: date,
      studentIds: studentIds ?? [],
    },
    {
      enabled: studentIds?.length > 0,
    }
  );

  const { data: coachingData } = trpc.studentCoaching.get.useQuery(
    {
      fromDate: date,
      toDate: date,
      studentIds: studentIds ?? [],
    },
    {
      enabled: studentIds?.length > 0,
    }
  );

  const { data: clipReviewCoachingData } = trpc.studentClipReviewCoaching.get.useQuery(
    {
      fromDate: date,
      toDate: date,
      studentIds: studentIds ?? [],
    },
    {
      enabled: studentIds?.length > 0,
    }
  );

  const { data: activeSubjects } = trpc.goals.getActiveSubjectsByMultipleStudentIds.useQuery(
    studentIds ?? [],
    {
      enabled: !!studentIds && studentIds.length > 0,
    }
  );

  const { data: masteryUnits } = trpc.masteryThresholds.get.useQuery(
    {
      studentIds: studentIds ?? [],
      isMinThreshold: false,
    },
    {
      enabled: studentIds?.length > 0,
    }
  );

  const getRosteredSubjectsWithNames = (): roster_subject_cards[] =>
    activeSubjects?.map((studentSubject) => ({
      studentName: getStudentNameById(studentSubject.studentId) || '',
      studentId: studentSubject.studentId,
      subjects: studentSubject.subjects,
    })) ?? [];
  const handleOpenAntipatternModal = (subject: string, app: string, studentId: string) => {
    const matchingAntipatterns = antipatternData?.filter(
      (ap: any) => ap.subject === subject && ap.student_id === studentId
    );
    if (matchingAntipatterns && matchingAntipatterns.length > 0) {
      setSelectedAntipatternData(matchingAntipatterns);
    }
  };

  const handleCloseAntipatternModal = () => setSelectedAntipatternData([]);

  const handleOpenCoachingModal = (subject: string, studentId: string) => {
    const coaching = dayjs(date) >= dayjs('2024-02-12') ? clipReviewCoachingData : coachingData; // AI-GEN - Copilot
    const matchingCoaching = coaching?.filter(
      // AI-GEN - Cursor - GPT4
      (c: any) => c.subject === subject && c.studentId === studentId
    );

    const learningSession = learningDayDataLatest?.find(
      (ls: merged_time_and_activity) =>
        ls.subject === subject && ls.campus_and_student_id === studentId
    );

    if (learningSession && siteConfig.data?.domain) {
      amplitudeService.trackClickCoaching(
        getCampusTypeByDomain(siteConfig.data.domain),
        learningSession
      );
    }

    if (matchingCoaching && matchingCoaching.length > 0) {
      setSelectedCoachingData(matchingCoaching);
    }
  };

  const handleCloseCoachingModal = () => setSelectedCoachingData([]); // AI-GEN - Cursor - GPT4

  return (
    <Box width={'100%'}>
      <PaginatedModal
        open={selectedAntipatternData.length > 0}
        onClose={handleCloseAntipatternModal}
      >
        {selectedAntipatternData?.map((item) => (
          <AntipatternBody key={item.id} antipattern={item} />
        ))}
      </PaginatedModal>
      <BasicModal
        open={selectedCoachingData.length > 0}
        onClose={handleCloseCoachingModal}
        sx={{ borderRadius: '8px' }}
      >
        {selectedCoachingData?.map((item) => (
          <CoachingBody
            key={item.coaching}
            coaching={item}
            title={
              role === Role.ADMIN
                ? `${item.subject} - ${getStudentNameById(item.studentId)}`
                : `${item.subject}`
            }
            date={date}
          />
        ))}
      </BasicModal>
      <DailyActivityTable
        masteryUnits={masteryUnits}
        learningDayData={learningDayDataLatest ?? []}
        onClickCell={handleOpenAntipatternModal}
        clipReviewCoachingData={
          // AI-GEN - Cursor - GPT4
          dayjs(date) >= dayjs('2024-02-12') ? clipReviewCoachingData : coachingData // AI-GEN - Cursor - GPT4
        } // AI-GEN - Cursor - GPT4
        onCoachingButtonClicked={handleOpenCoachingModal}
        isLoading={isStillFetching}
        selectedStudents={selectedStudents}
        rosteredSubjects={getRosteredSubjectsWithNames()}
      />
      {debugView ? (
        <DailyActivityTable2h
          learningDayData={learningDayDataLatest ?? []}
          isLoading={isStillFetching}
          selectedStudents={selectedStudents}
        />
      ) : null}
    </Box>
  );
};

type Props = {
  date: string;
  debugView: boolean;
  onLoad: (data: any) => void;
};
export default LearningDaySummary;
