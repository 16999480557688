import { useAppSelector } from './useAppSelector';
import { getStudentNameById, getLevelById } from '@common/components/daily-activity/util';
import useStudentList from './useStudentList';

export type Student = {
  id?: string;
  name?: string;
  level?: string;
};

const useStudentSelection = () => {
  const studentIds = useAppSelector((state) => state.dashboardFilterSelections.selectedStudents);
  const rosterData = useStudentList();
  const students: Student[] = studentIds
    .map((studentId) => ({
      id: studentId,
      name: getStudentNameById(rosterData, studentId),
      level: getLevelById(rosterData, studentId),
    }))
    .filter(
      (student) => student?.id !== null && student?.name !== null && student?.name !== undefined
    );

  return students;
};

export default useStudentSelection;
