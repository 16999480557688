import { Box, ToggleButtonGroup, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface ToggleProps {
  label: string;
  value: string;
  children: ReactNode[];
  clickHandler: (event: React.SyntheticEvent, value: any) => void;
}

const ToggleWrapper = ({ label, value, clickHandler, children }: ToggleProps) => (
  <Box
    sx={{
      display: 'table-row',
    }}
  >
    <Typography sx={{ display: 'table-cell', paddingRight: '24px' }}>{label}</Typography>
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={clickHandler}
      aria-label="Platform"
      sx={{ display: 'table-cell' }}
    >
      {children}
    </ToggleButtonGroup>
  </Box>
);
export default ToggleWrapper;
